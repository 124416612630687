import styled from 'styled-components';
import { useQuery } from 'react-query';
import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import { fetchVotingDistribution } from '../services';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Loader, TitleComponent, VotingPoll } from '../components';
import moment from 'moment';
import { toVotingPollData } from '../utils/vote';
import { useIonViewWillEnter } from '@ionic/react';

const HomeWrapper = styled.div`
  padding: 25px;
  padding-bottom: 0px;
  .voting-text-wrapper {
    text-align: center;
    padding-top: 25px;
    h5 {
      color: #4d4d4d;
      font-weight: bold;
      line-height: 26px;
      font-size: 18px;
    }
  }
`;

const VotingPollMainWrapper = styled.div`
  margin-top: 30px;
`;

const EachVotingPollWrapper = styled.div`
  margin-bottom: 30px;
`;

const VotingDistributionPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('item');
  const listParam = searchParams.get('list');

  const { teamDetails, userProfileData } = useContext(UserContext);

  const {
    data: votesDistributionData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(['fetchVoteDistribution', id, userProfileData?.id], fetchVotingDistribution, {
    enabled: !!userProfileData?.id && id !== null,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: (response) => {
      return {
        ...response?.data,
        closeDate: moment(response?.data?.closeDate).format('YYYY.MM.DD HH:MM'),
      };
    },
  });

  useIonViewWillEnter(() => {
    async function fetch() {
      if (userProfileData?.id) {
        await refetch();
      }
    }
    fetch();
  }, [userProfileData?.id]);

  return (
    <Wrapper authenticated={true}>
      <HomeWrapper>
        <TitleComponent
          subHeading="ブロンコスに意見を届けよう！"
          heading="Vote"
          color={teamDetails?.team?.teamColor}
          title
        />
        <div className="voting-text-wrapper">
          <h5>{t('Votings')}</h5>
        </div>
        <Loader
          isLoading={isLoading || isFetching || userProfileData?.id === undefined}
          className="home-loader"
        >
          <VotingPollMainWrapper>
            <EachVotingPollWrapper>
              <VotingPoll
                data={toVotingPollData(
                  votesDistributionData?.selections,
                  votesDistributionData?.selectionType,
                  votesDistributionData?.results
                )}
                // data={[votesDistributionData]}
                avatar={teamDetails?.team?.teamImagePath}
                title={votesDistributionData?.title}
                subtitle={votesDistributionData?.content}
                color={teamDetails?.team?.teamColor}
                allowsMultiple={votesDistributionData?.selectionType === 'MultipleSelect'}
                id={votesDistributionData?.id}
                deadline={votesDistributionData?.closeDate}
                showDistribution={true}
                listParam={listParam}
              />
            </EachVotingPollWrapper>
          </VotingPollMainWrapper>
        </Loader>
      </HomeWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(VotingDistributionPage);
