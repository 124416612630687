import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import { Button, TitleComponent } from '../components';
import Wrapper from '../components/organisms/Wrapper/Wrapper';

const ContentWrapper = styled.div`
  padding: 0px;
  .confirmation-text-wrapper {
    text-align: center;
    padding: 50px 70px 65px 70px;
    color: #4d4d4d;
    p {
      font-size: 15px;
      font-weight: 350;
      margin: 0px;
      padding: 0px;
    }
  }
  .button-wrapper {
    padding: 0 35px;
    padding-bottom: 40px;
    .yes-button {
      margin-top: 30px;
    }
  }
`;

const AccountDeleteConfirmation = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  return (
    <Wrapper authenticated>
      <ContentWrapper>
        <TitleComponent title heading="Delete Account" subHeading="Withdrawl" color="#29C8D2" />
        <div className="confirmation-text-wrapper">
          <p>{t('Are you sure')}</p>
          <p>{t('you want to delete your account?')}</p>
        </div>
        <div className="button-wrapper">
          <Button size="large" onClick={() => push('/profile')}>
            {t('Cancel')}
          </Button>
          <Button size="large" className="yes-button" onClick={() => push('/withdrawl-careful')}>
            {t('Yes')}
          </Button>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(AccountDeleteConfirmation);
