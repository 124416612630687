import { LineLoginConfig, NetworkConfig, WalletThemeConfig } from '../types/embed/embed-config';
const { REACT_APP_LOGIN_DOMAIN, REACT_APP_LINE_CLIENTID, REACT_APP_GOOGLE_CLIENTID } = process.env;

export const lineLoginConfig: LineLoginConfig = {
  name: 'LINE', //button login title
  description: 'LINE with UPBOND Identity',
  typeOfLogin: 'line',
  loginProvider: 'upbond-line',
  jwtParameters: {
    domain: REACT_APP_LOGIN_DOMAIN!,
    connection: 'line',
    clientId: REACT_APP_LINE_CLIENTID!,
    client_id: REACT_APP_LINE_CLIENTID!,
    scope: 'openid email profile offline_access',
  },
  showOnModal: true,
  showOnDesktop: true,
  showOnMobile: true,
  mainOption: true,
  priority: 1, // are in the first pile in login modal
  logo: 'https://elvira.co.th/wp-content/uploads/2016/02/line-icon.png',
  buttonBgColor: '#289B2A',
  buttonTextColor: '#f3f3f3',
  clientId: REACT_APP_LINE_CLIENTID,
};

export const googleLoginConfig: any = {
  name: 'Google',
  description: 'Google',
  typeOfLogin: 'jwt',
  loginProvider: 'upbond-google',
  jwtParameters: {
    domain: REACT_APP_LOGIN_DOMAIN,
    connection: 'line',
    client_id: REACT_APP_GOOGLE_CLIENTID,
    clientId: REACT_APP_GOOGLE_CLIENTID,
    scope: 'openid email profile offline_access',
  },
  clientId: REACT_APP_GOOGLE_CLIENTID,
  logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu1PJmT_THldF0n5APcmt9p10utgu6KSw4cH2fQ5Xhpw&s',
  buttonBgColor: '#000000',
  buttonTextColor: '#000000',
  showOnModal: true,
  showOnDesktop: true,
  showOnMobile: true,
  mainOption: true,
  priority: 2,
};

export const walletThemeConfig: WalletThemeConfig = {
  logo: 'https://nftasset.s3.ap-northeast-1.amazonaws.com/UPBONDbondcustomer.png',
  buttonLogo: 'https://nftasset.s3.ap-northeast-1.amazonaws.com/webinar/Round+Logo.png',
  name: 'Company',
  isActive: true,
  lang: `${window.navigator.language}`, //make your wallet language to japanese or english, default "en"
  modalColor: '#fffff',
  bgColor: '#4B68AE',
  bgColorHover: '#214999',
  textColor: '#f3f3f3',
  textColorHover: '#214999',
  upbondLogin: {
    globalBgColor: '#ffffff',
    globalTextColor: '#4B68AE',
  },
};

export const networkConfig: NetworkConfig = {
  /**
   * If you need the embed give your different network or blockchain for daaps
   * Default "mumbai network"
   *
   */
  // chainId: 80001,
  // networkName: 'Mumbai',
  // tickerName: 'Mumbai',
  // ticker: 'Mumbai',
  // host: 'mumbai',
  // rpcUrl: 'https://polygon-testnet.public.blastapi.io/',
  // blockExplorer: 'https://mumbai.polygonscan.com/',
  host: "matic",
  chainId: 137,
  networkName: "Polygon",
  blockExplorer: "https://polygonscan.com/",
  ticker: "MATIC",
  tickerName: "MATIC",
  rpcUrl: "https://polygon-rpc.com",  
};
