import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonHeader, IonImg } from '@ionic/react';
import { wallet, menu, notifications } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';

import { useToast } from '../../../hooks/useToast';
import { fetchNotifications } from '../../../services';
import { UserContext } from '../../../utils/UserContext';
import { AuthContext } from '../../../utils/AuthContext';
import { Button, Toast } from '../../../components';
import OffCanvasHeader from './OffCanvas';

interface IHeaderProps {
  authenticated: boolean;
  color?: string;
}

const StyledIonHeader = styled(IonHeader)<any>`
  display: flex;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
  z-index: 999;
  background: #eee;
  ion-img {
    cursor: pointer;
    width: 105px;
  }
  .buttons-wrapper {
    display: flex;
  }
  ion-button {
    margin-left: 20px;
  }
  ion-icon {
    margin: auto;
  }
  .alert-btn {
    cursor: pointer;
  }

  @media (max-width: 380px) {
    ion-button {
      margin-left: 10px;
    }
    ion-img {
      width: 95px;
    }
  }
`;

const Header: React.FC<IHeaderProps> = ({ authenticated: authenticatedProps = false, color }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { teamDetails, userProfileData, isFirstStepCompleted } = useContext(UserContext);

  const [showToast, setShowToast] = useToast(false);
  const { login, cognitoUser, upInit } = useContext(AuthContext);

  const [showLogo, setShowLogo] = useState<boolean>(false);
  const [primaryMenuType, setPrimaryMenuType] = useState<string>('');
  const [primaryMenuItems, setPrimaryMenuItems] = useState<any>(null);

  // Off Canvas
  const [show, setShow] = useState<boolean>(false);

  // Login Alert
  const [elapsedTime, setElapsedTime] = useState(0);
  const timerId = React.useRef<number | undefined>(undefined);

  const PRIMARY_MENU_ITEMS = [
    {
      link: '/top',
      text: ['Top', 'トップページ'],
    },
    {
      link: '/team-list',
      text: ['Team Select', 'チーム選択'],
    },
    {
      link: '/market',
      text: ['NFT Market', 'NFTカード購入'],
    },
    {
      // link: 'https://scalagrp.notion.site/About-f207ef52f7c54223abe7a78337b5a016',
      link: '/about',
      text: ['About', 'Faniqueを知る'],
    },
    {
      // link: 'https://scalagrp.notion.site/How-to-ba9e3dd90e3044e48297eba1f63fc9a3',
      link: '/how-to-use',
      text: ['How To', '詳しく使い方'],
    },
  ];

  const AUTHENTIC_PRIMARY_MENU_ITEMS = [
    {
      link: '/home',
      text: ['Home', 'チームホームページ'],
    },
    {
      link: '/voting-list?list=ongoingVote',
      text: ['Vote', '投票'],
    },
    {
      link: '/my-page',
      text: ['My Page', 'マイページ'],
    },
    {
      link: '/market',
      text: ['NFT Market', 'NFTカード購入'],
    },
    {
      link: '/chat',
      text: ['Chat', 'ディスコード'],
    },
    {
      link: '/team-select',
      text: ['Team Select', 'チーム選択'],
      options: [
        {
          link: `/team-list/${teamDetails?.team?.id}`,
          text: teamDetails?.team?.teamName,
        },
        {
          link: `/team-list`,
          text: '他のチームを探す',
        },
      ],
    },
    {
      // link: 'https://scalagrp.notion.site/About-f207ef52f7c54223abe7a78337b5a016',
      link: '/about',
      text: ['About', 'Faniqueを知る'],
    },
    {
      // link: 'https://scalagrp.notion.site/How-to-ba9e3dd90e3044e48297eba1f63fc9a3',
      link: '/how-to-use',
      text: ['How To', '詳しく使い方'],
    },
  ];

  const SECONDARY_MENU_ITEMS = [
    {
      link: '/terms-and-conditions',
      text: t('Terms & Conditions'),
    },
    {
      link: '/privacy-policy',
      text: t('Privacy Policy'),
    },
    {
      link: '/specified-commercial-transactions',
      text: t('Specified Commercial Transactions'),
    },
    {
      link: '/inquiry',
      text: t('Inquiry'),
    },
  ];

  // Off Canvas Menu
  const toggleOffCanvasMenu = (menu?: string) => {
    menu && setPrimaryMenuType(menu);
    if (menu && show && primaryMenuType !== menu) {
      setShow(true);
    } else {
      setShow(!show);
    }
  };

  const { data: notificationData, isLoading: isNotificationLoading } = useQuery(
    ['fetchNotifications', userProfileData?.id],
    fetchNotifications,
    {
      enabled: primaryMenuType === 'notification',
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (response) => {
        return response.data;
      },
    }
  );

  // Off Canvas Mneu
  useEffect(() => {
    if (show) {
      document.querySelector('ion-content')?.classList.add('no-scroll');
    } else {
      const timeoutId = setTimeout(() => {
        setPrimaryMenuType('');
      }, 300);
      document.querySelector('ion-content')?.classList.remove('no-scroll');

      return () => clearTimeout(timeoutId);
    }
  }, [show]);

  const handleLogin = async () => {
    try {
      await login();
    } catch (e) {
      setShowToast(true);
    }
  };

  const findNotifDate = (published_date: string) => {
    const date = new Date(published_date);
    const time = `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}年${month}月${day}日 ${time}`;
  };

  useEffect(() => {
    if (primaryMenuType === 'notification') {
      setShowLogo(true);
      if (notificationData) {
        let items: any = [];
        notificationData.forEach((item: any) => {
          let chunk = {
            link: `/notification/${item.id}`,
            text: [item.title, findNotifDate(item.publishDate)],
          };
          items.push(chunk);
        });
        setPrimaryMenuItems(items);
      }
    }
    if (primaryMenuType === 'authentic_primary') {
      setPrimaryMenuItems(AUTHENTIC_PRIMARY_MENU_ITEMS);
      setShowLogo(true);
    }
    if (primaryMenuType === 'guest_primary') {
      setPrimaryMenuItems(PRIMARY_MENU_ITEMS);
      setShowLogo(false);
    }
  }, [primaryMenuType, notificationData]);

  const onWalletClickHandler = () => {
    // window.open('https://wallet.upbond.io/login', '_blank');
    window.open('https://login.upbond.io/', '_blank');
  };

  // Login Alert
  useEffect(() => {
    timerId.current = window.setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
    }, 1000);

    return () => {
      if (timerId.current) clearTimeout(timerId.current);
    };
  }, []);

  useEffect(() => {
    if (elapsedTime >= 5) {
      if (timerId.current) clearTimeout(timerId.current);
    }
  }, [elapsedTime]);

  const handleAlert = () => {
    if (!upInit) {
      alert(
        `ブラウザにてサードパーティクッキーをブロック設定をOFFに変更をお願い致します。変更後もログインができない場合は、大変お手数ですがお問い合わせよりご報告をお願い致します。`
      );
    }
  };

  return (
    <>
      <StyledIonHeader className="ion-no-border" id="sticky-header">
        {showToast && <Toast message="Cannot login at the moment" type={'Error'} />}

        <div className="logo-wrapper">
          <Link to="/top">
            <IonImg src="./assets/images/logo.png" />
          </Link>
        </div>
        <div className="buttons-wrapper">
          {authenticatedProps || isFirstStepCompleted ? (
            <>
              <Button
                size="small"
                color={teamDetails?.team?.teamColor}
                icon={wallet}
                className={primaryMenuType === 'wallet' ? 'active-menu' : ''}
                onClick={onWalletClickHandler}
              />
              <Button
                size="small"
                color={teamDetails?.team?.teamColor}
                icon={notifications}
                onClick={() => toggleOffCanvasMenu('notification')}
                className={primaryMenuType === 'notification' ? 'active-menu' : ''}
              />
              <Button
                className={primaryMenuType.indexOf('primary') > -1 ? 'active-menu' : ''}
                size="small"
                color={teamDetails?.team?.teamColor}
                icon={menu}
                onClick={() => toggleOffCanvasMenu('authentic_primary')}
              />
            </>
          ) : cognitoUser ? (
            <>
              <Button
                size="small"
                color={teamDetails?.team?.teamColor}
                icon={wallet}
                className={primaryMenuType === 'wallet' ? 'active-menu' : ''}
                onClick={onWalletClickHandler}
              />
              <Button
                size="small"
                color={color}
                disabled
                icon={notifications}
                onClick={() => toggleOffCanvasMenu('notification')}
                className={primaryMenuType === 'notification' ? 'active-menu' : ''}
              />
              <Button
                className={primaryMenuType.indexOf('primary') > -1 ? 'active-menu' : ''}
                size="small"
                color={authenticatedProps ? color : undefined}
                icon={menu}
                onClick={() =>
                  toggleOffCanvasMenu(authenticatedProps ? 'authentic_primary' : 'guest_primary')
                }
                disabled={history.location.pathname.includes('nft-generation')}
              />
            </>
          ) : (
            <>
              <div
                onClick={elapsedTime >= 5 ? handleAlert : undefined}
                className={elapsedTime >= 5 ? 'alert-btn' : ''}
              >
                <Button size="small" onClick={handleLogin} disabled={!upInit}>
                  {t('Login/Register')}
                </Button>
              </div>
              {/* Off Canvas */}
              <Button
                className={primaryMenuType.indexOf('primary') > -1 ? 'active-menu' : ''}
                size="small"
                color={color}
                icon={menu}
                onClick={() =>
                  toggleOffCanvasMenu(authenticatedProps ? 'authentic_primary' : 'guest_primary')
                }
              />
            </>
          )}
        </div>
      </StyledIonHeader>

      {/* Off Canvas */}
      <OffCanvasHeader
        show={show}
        setShow={setShow}
        isFetching={isNotificationLoading && primaryMenuType === 'notification'}
        primaryMenuItems={primaryMenuItems}
        secondaryMenuItems={primaryMenuType !== 'notification' ? SECONDARY_MENU_ITEMS : []}
        closeMenu={() => toggleOffCanvasMenu('')}
        logoPath={showLogo ? '/assets/images/broncos_image_white.png' : undefined}
        isAuthenticated={false}
        color={
          authenticatedProps || isFirstStepCompleted
            ? color || teamDetails?.team?.teamColor
            : undefined
        }
      />
    </>
  );
};

export { Header };
