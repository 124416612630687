import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, TitleComponent, ListWithTitle } from '../components';

const ContentWrapper = styled.div`
  padding: 20px;
  padding-top: 0px;
  .divider-wrapper {
    padding: 0px 15px;
    hr {
      margin: 0px;
    }
  }
  .box {
    margin-top: 40px;
    border-radius: 50px;
    .each-list-wrapper {
      padding: 30px 75px;
    }
  }
  .buttons-wrapper {
    margin-top: 35px;
    padding: 0px 15px;
    margin-bottom: 15px;
    .cancel-button {
      margin-top: 22px;
    }
  }
`;

const COLOR = '#E41840';
const UNAVAILABLE_FEATURE_LIST = [
  'NFT membership',
  'Plan voting',
  'Digital card trade',
  'Discord community participation',
];
const DATA_TO_BE_DELETED = ['Points', 'Saved digital cards'];

const WithdrawlCarefulPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Wrapper authenticated>
      <ContentWrapper>
        <TitleComponent title heading="Warning" subHeading="Caution" color={COLOR} />
        <div className="neomorphs-shadow-in box">
          <div className="each-list-wrapper">
            <ListWithTitle
              title={t('When you delete, these features are no longer available')}
              data={UNAVAILABLE_FEATURE_LIST}
            />
          </div>
          {/* <div className="divider-wrapper">
            <Divider />
          </div> */}
          {/* <div className="each-list-wrapper">
            <ListWithTitle
              title={t('The following digital data will be deleted.')}
              data={DATA_TO_BE_DELETED}
            />
          </div> */}
        </div>
        <div className="buttons-wrapper">
          <Button size="large" onClick={() => history.push('/withdrawl-confirmation')}>
            {t('Confirm')}
          </Button>
          <Button size="large" className="cancel-button" onClick={() => history.push('/profile')}>
            {t('Cancel')}
          </Button>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(WithdrawlCarefulPage);
