import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useState, useContext } from 'react';

import { fetchTeamLists } from '../services';
import RestrictedRoute from '../withRestrictedRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Loader, Pagination, SearchBar, TitleComponent, TeamCard } from '../components';

const EachTeamCard = styled.div`
  margin: 0px 20px;
  margin-bottom: 52px;
  border-radius: 50px;
  padding: 0px 15px;
  padding-top: 15px;
  padding-bottom: 20px;
`;

const TeamWrapper = styled.div`
  margin-top: 30px;
  .pagination-wrapper {
    padding: 0px 40px;
    margin-bottom: 35px;
  }
  .team {
    > :last-child {
      margin-bottom: 30px;
    }
  }
`;

const Message = styled.span`
  font-size: 14px;
  display: flex;
  justify-content: center;
`;

const TeamList = () => {
  const { t } = useTranslation();
  const [totalPages, setTotalPages] = useState('1');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  const { teamDetails } = useContext(UserContext);

  const { data, isLoading } = useQuery(['fetchTeamList', currentPage, searchText], fetchTeamLists, {
    retry: 1,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    select: ({ data }) => ({
      teams: data.teams.map((team: any) => ({
        id: team.id,
        name: team.teamName,
        detail: team.description,
        image: team.teamImagePath,
      })),
      totalPages: data.total,
    }),
    onSuccess(data) {
      setTotalPages(data.totalPages.toString());
    },
  });

  return (
    <Wrapper>
      <div id="title">
        <TitleComponent
          color={teamDetails?.team?.teamColor}
          heading="Team Select"
          subHeading="Select the team for membership"
          title
        />
      </div>
      <SearchBar
        setSearchText={setSearchText}
        setCurrentPage={setCurrentPage}
        searchText={searchText}
      />
      <TeamWrapper>
        <div className="team">
          <Loader isLoading={isLoading}>
            {
              // For later response
              /* data && data?.teams.length === 0 && */
              data === undefined && <Message>{t('No Data Available')}</Message>
            }

            {(data?.teams || [])?.map((team: any) => (
              <EachTeamCard className="neomorphs-shadow-in" key={team.id}>
                <TeamCard
                  team={team}
                  buttonText={t('See details')}
                  nextRoute={`/team-list/${team.id}`}
                />
              </EachTeamCard>
            ))}
          </Loader>
        </div>
        <div className="pagination-wrapper">
          {!isLoading && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </TeamWrapper>
    </Wrapper>
  );
};

export default RestrictedRoute(TeamList);
