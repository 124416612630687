import React from 'react';
import { useQuery } from 'react-query';

import { fetchTeamDetail } from '../services';

const useGetTeamDetail = (id: any) => {
  const { data, isLoading, isError } = useQuery(['fetchTeamDetails', id], fetchTeamDetail, {
    retry: 1,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: !!id,
    select: (response: any): any => {
      return {
        color: response?.data?.teamColor,
        logo: response?.data?.logoImagePath,
        image: response?.data?.teamImagePath,
        feature: response?.data?.feature,
        mission: response?.data?.mission,
        message: response?.data?.message,
        name: response?.data?.teamName,
        subHeading: response?.data?.tag,
        status: response?.data?.statuses?.items,
        teamSlogan: response?.data?.teamSlogan,
        primaryLogoImagePath: response?.data?.primaryLogoImagePath,
        primaryLogoMessage: response?.data?.primaryLogoMessage,
        salesStopPeriodFrom: response?.data?.salesStopPeriodFrom,
        salesStopPeriodTo: response?.data?.salesStopPeriodTo,
      };
    },
  });
  return { data, isLoading, isError };
};

export { useGetTeamDetail };
