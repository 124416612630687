import { IonImg } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Button } from '../Button';

const Wrapper = styled.div`
  display: flex;
  ion-img {
    height: 77px;
    width: 77px;
    ::part(image) {
      border-radius: 50%;
    }
  }
  .avatar-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .username-section {
    flex-grow: 1;
    p {
      color: #4d4d4d;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0.8rem;
      padding-left: 0.5rem;
    }
  }
`;

interface Props {
  avatar?: string;
  username?: string;
  color?: string;
}

const UsernameCard: React.FC<Props> = ({
  avatar = './assets/avatar/user-avatar.png',
  username,
  color,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Wrapper>
      <div className="avatar-section">
        <IonImg src={avatar} />
      </div>
      <div className="username-section">
        <p> {username ?? t('User full name')}</p>
        <Button expand="full" size="small" color={color} onClick={() => history.push('/settings')}>
          {t('Settings')}
        </Button>
      </div>
    </Wrapper>
  );
};

export { UsernameCard };
