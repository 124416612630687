import React from 'react';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';

interface ITopCard {
  logo: string;
  image: string;
}

const Wrapper = styled.div`
  padding: 0 15px;
  padding-bottom: 20px;
  border-radius: 50px;
  .logo-wrapper {
    display: flex;
    padding: 25px 0px;
    justify-content: center;
    .logo {
      width: 250px;
      height: 55px;
    }
  }
  ion-img::part(image) {
    border-radius: 50px;
  }
  .image-wrapper {
    display: flex;
    justify-content: center;
    .image {
      width: 100%;
    }
  }
`;

const TopCard: React.FC<ITopCard> = ({ logo, image }) => {
  return (
    <Wrapper className="neomorphs-shadow-out">
      <div className="logo-wrapper">
        <IonImg src={logo} className="logo" />
      </div>
      <div className="image-wrapper">
        <IonImg src={image} className="image" />
      </div>
    </Wrapper>
  );
};

export { TopCard };
