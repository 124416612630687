import styled from 'styled-components';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import { Button, TitleComponent } from '../components';
import Wrapper from '../components/organisms/Wrapper/Wrapper';

const Container = styled.div`
  text-align: center;
  min-height: 35em;

  .page-title {
    font-weight: 350;
    font-size: 16px;
  }
  .title-container {
    line-height: 0px !important;
  }
  .title {
    font-weight: 800;
    margin-top: -0.3em;
    font-size: 30px !important;
  }
  .options-button {
    margin: 35px 28px;
    font-size: 17.5px;

    @media (max-width: 390px) {
      font-size: 16px;
    }

    @media (max-width: 375px) {
      font-size: 14px;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .back-button {
    margin-top: 1.2em;
    width: 10em;
  }
`;

const Settings: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { teamDetails, status } = useContext(UserContext);

  const handleLogout = () => {
    // logout functions here

    history.push('/logout');
  };

  const color = teamDetails?.team?.teamColor;

  // const otherInfoTerm = () => {
  //   if (status?.statusNameEnglish !== 'RED' && status?.statusNameEnglish !== 'SILVER') {
  //     return (
  //       <Button
  //         size="large"
  //         className="options-button"
  //         color={color}
  //         onClick={() => history.push('/other-information')}
  //       >
  //         {t('Team info confirm')}
  //       </Button>
  //     );
  //   }
  // };

  return (
    <Wrapper authenticated={true}>
      <Container>
        <div className="title-container">
          <p className="page-title">{t('Settings')}</p>
          <TitleComponent title={true} heading="Settings" className="title" color={color} />
        </div>
        <Button
          size="large"
          className="options-button"
          color={color}
          onClick={() => history.push('/profile')}
        >
          {t('Personal & payment info')}
        </Button>
        <Button
          size="large"
          className="options-button"
          color={color}
          onClick={() => history.push('/membership-card-info')}
        >
          {t('Membership card info')}
        </Button>
        {/* {otherInfoTerm()} */}
        <Button
          size="large"
          className="options-button"
          onClick={() => handleLogout()}
          color={color}
        >
          {t('Logout')}
        </Button>
        <div className="button-container">
          <Button
            size="small"
            className="back-button"
            onClick={() => history.push('/my-page')}
            color={color}
          >
            {t('Back')}
          </Button>
        </div>
      </Container>
    </Wrapper>
  );
};

export default PrivateRoute(Settings);
