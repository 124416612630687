import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import { Button, TitleComponent } from '../components';
import Wrapper from '../components/organisms/Wrapper/Wrapper';

const Content = styled.div`
  padding: 0 20px;

  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;

    .back-btn {
      width: 140px;
    }
  }

  .card-wrapper {
    padding: 30px 50px 30px;
    border-radius: 50px;
    margin-top: 40px;

    display: flex;
    align-items: center;
    flex-direction: column;

    font-weight: 350;

    .date-time {
      font-size: 13px;
      margin-top: 30px;
    }

    .info {
      text-align: center;
      margin-top: 16px;
      margin-bottom: 10px;

      p {
        font-size: 13px;
        margin: 0px;
      }
    }

    .qr-wrapper {
      position: relative;

      .corner {
        position: absolute;
        width: 37px;
        left: 0;
      }

      .right {
        left: calc(100% - 37px);
        transform: rotate(90deg);
      }

      .bottom-left {
        bottom: 0;
        left: 0;
        transform: rotate(270deg);
      }

      .bottom-right {
        bottom: 0;
        left: calc(100% - 37px);
        transform: rotate(180deg);
      }

      .qr-code {
        padding: 25px;
      }
    }
  }
`;

const QrCodeGeneration = () => {
  const { qrCode, userProfileData, teamDetails } = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const FULL_NAME = userProfileData?.name_sei + ' ' + userProfileData?.name_mei;

  const currentdate: any = new Date();
  const dateValue = `${currentdate?.getFullYear()}/${String(currentdate?.getMonth() + 1).padStart(
    2,
    '0'
  )}/${String(currentdate?.getDate()).padStart(2, '0')} ${currentdate?.getHours()}:${String(
    currentdate?.getMinutes()
  ).padStart(2, '0')}`;

  return (
    <Wrapper authenticated>
      <Content>
        <TitleComponent
          heading="QR Code"
          title
          color={teamDetails?.team?.teamColor}
          subHeading="QR code"
        />
        <div className="neomorphs-shadow-in card-wrapper">
          <div className="qr-wrapper">
            <IonImg src="/assets/images/corner.png" className="corner" />
            <IonImg src="/assets/images/corner.png" className="corner right" />
            <IonImg src="/assets/images/corner.png" className="corner bottom-left" />
            <IonImg src="/assets/images/corner.png" className="corner bottom-right" />
            <div className="qr-code">
              <img src={qrCode} />
            </div>
          </div>

          <div className="date-time">{dateValue}</div>

          <div className="info">
            <p>{FULL_NAME}</p>
            <p>
              {t('Membership ID')}: {userProfileData?.id}
            </p>
          </div>
        </div>

        <div className="btn-wrapper">
          <Button
            size="small"
            className="back-btn"
            color={teamDetails?.team?.teamColor}
            onClick={() => history.goBack()}
          >
            {t('Back')}
          </Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default PrivateRoute(QrCodeGeneration);
