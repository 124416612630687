import { IonCol, IonRow } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import { Button, Divider } from '../../atoms';
import { useHistory } from 'react-router';

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 50px;
  padding: 1rem;
  & > ion-row > ion-col {
    height: 65px;
    align-items: center;
    display: flex;
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1em;
    padding-left: 1rem;
    color: ${(props: any) => props.color};
  }
  .value {
    font-size: 25px;
    font-weight: bold;
  }
  .point > span {
    font-size: 13px;
  }
  .history-button {
    justify-content: end;
    ion-button {
      border-radius: 50%;
      width: 43px;
      height: 43px;
    }
  }
  .children {
    height: fit-content;
  }
  .value {
    color: #4d4d4d;
  }
`;

interface Props {
  children?: any;
  status: string;
  level: string;
  point: string;
  color?: string;
  totalCoin?: number;
}

const TeamDescription: React.FC<Props> = ({ children, status, level, point, color, totalCoin }) => {
  const history = useHistory();

  return (
    <Wrapper className="neomorphs-shadow-in" color={color}>
      <IonRow>
        <IonCol size="5">
          <span className="label">Membership status</span>
        </IonCol>
        <IonCol size="7">
          <span className="value">{status}</span>
        </IonCol>
      </IonRow>
      <Divider />
      <IonRow>
        <IonCol size="5">
          <span className="label">Level</span>
        </IonCol>
        <IonCol size="6">
          <span className="value">{level}</span>
        </IonCol>
      </IonRow>
      <Divider />
      <IonRow>
        <IonCol size="5">
          <span className="label">Coin</span>
        </IonCol>
        <IonCol>
          <span className="value point">
            {point}
            <span>coin</span>
          </span>
        </IonCol>
        <IonCol className="history-button">
          <Button
            expand="block"
            size="small"
            color={color}
            onClick={() => history.push('/transaction-history')}
          >
            履歴
          </Button>
        </IonCol>
        <Divider />
      </IonRow>
      <Divider />
      <IonRow>
        <IonCol size="5">
          <span className="label">
            Total <br /> Coins
          </span>
        </IonCol>
        <IonCol size="6">
          <span className="value">
            <span className="value point">
              {totalCoin?.toLocaleString('en-US')}
              <span>coin</span>
            </span>
          </span>
        </IonCol>
      </IonRow>
      <Divider />
      <div className="children">{children}</div>
    </Wrapper>
  );
};

export { TeamDescription };
