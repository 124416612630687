import React from 'react';
import styled from 'styled-components';
import { Divider } from '../../atoms';

const Wrapper = styled.div`
  padding-bottom: 15px;

  .header {
    text-align: center;

    .heading {
      font-size: 28px;
      font-weight: 900;
      line-height: 40px;
      color: #4d4d4d;
    }
  }

  .list-section {
    padding: 10px 0px 15px 0px;
  }

  .wrap {
    display: flex;
    padding: 10px 0px 0px 10px;
    margin-bottom: 10px;

    .count {
      height: 46px;
      min-width: 46px;
      border-radius: 50%;
      box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf, inset -3px -4px 4px -4px #a7a4a4,
        inset 3px 5px 9px -7px #f9e6e6;
      justify-content: center;
      display: flex;
      padding: 9px;
      color: #29c8d2;
      font-weight: 900;
      font-size: 31px;
      margin: 5px 10px 0 0;
    }
  }
  .inner-title {
    color: #4d4d4d;
    padding: 0 10px;
    .list-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      margin: 0;
    }
  }
  .inner-para {
    color: #4d4d4d;
    padding: 0px 10px;
    .list-para {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      padding-bottom: 10px;
      margin: 0;
    }
  }
  .arrow-wrap {
    display: flex;
    justify-content: center;
  }
  .highlight-heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #29c8d2;
    padding: 15px 0px 10px;
    margin: 0;
  }
  .image-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 10px;

    .image {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .p-l {
    padding: 20px 30px !important;
  }
`;

export const MembershipInformation = () => {
  return (
    <div className="neomorphs-shadow-in card-wrapper">
      <Wrapper>
        <div className="header">
          <p className="heading">
            メンバーシップを <br />
            保有するとできること
          </p>
        </div>

        <Divider />

        {/* A */}
        <div className="list-section">
          <div className="wrap">
            <p className="count">A</p>
            <div className="inner-title">
              <p className="list-title">
                試合会場でコインを貯めて
                <br />
                メンバーシップを育てる
              </p>
            </div>
          </div>
          <div className="inner-para">
            <p className="list-para">
              来場コインを貯めて、メンバーシップのレベルアップや
              <br />
              ステータスアップが可能
            </p>
          </div>
          <p className="highlight-heading">コイン獲得方法</p>
          <div className="image-wrapper">
            <img
              src={'/assets/images/how-to-use/howToGetCoins.png'}
              className="image"
              height="200px"
              width="300"
            />
          </div>
          <p className="highlight-heading">ステータスをアップする</p>
          <div className="image-wrapper">
            <img
              src={'/assets/images/how-to-use/howToStatusUp.png'}
              className="image"
              height="200px"
              width="300"
            />
          </div>
          <div className="inner-para">
            <p className="list-para">
              1ステータスアップに必要なレベルは
              <br />
              5〜20レベルの範囲
            </p>
          </div>
        </div>

        <Divider />

        {/* B */}
        <div className="list-section">
          <div className="wrap">
            <p className="count">B</p>
            <div className="inner-title">
              <p className="list-title">
                Fanique限定
                <br />
                NFTカードの購入
              </p>
            </div>
          </div>
          <div className="inner-para">
            <p className="list-para">
              メンバーシップ保有者限定で選手やチームの
              <br />
              オリジナルNFTカードを売買可能
            </p>
          </div>
          <div className="image-wrapper p-l">
            <img
              src={'/assets/images/how-to-use/howToPlayerCard.png'}
              className="image drop-shadow"
              height="400px"
              width="300"
            />
          </div>
          <div className="inner-para">
            <p className="list-para">選手カードの販売機能は後日、公開予定</p>
          </div>
        </div>

        <Divider />

        {/* C */}
        <div className="list-section">
          <div className="wrap">
            <p className="count">C</p>
            <div className="inner-title">
              <p className="list-title">
                チーム運営に関する
                <br />
                投票企画への参加
              </p>
            </div>
          </div>
          <div className="inner-para">
            <p className="list-para">
              メンバーシップ保有者限定でチームの大事な
              <br />
              意思決定に関与することが可能
            </p>
          </div>
          <div className="image-wrapper p-l">
            <img
              src={'/assets/images/how-to-use/howToVotingCard.png'}
              className="image drop-shadow"
              height="350px"
              width="300"
            />
          </div>
          <div className="inner-para">
            <p className="list-para">投票機能は後日、公開予定</p>
          </div>
        </div>

        <Divider />

        {/* D */}
        <div className="list-section">
          <div className="wrap">
            <p className="count">D</p>
            <div className="inner-title">
              <p className="list-title">
                各ステータスの
                <br />
                特典・サービスを受ける
              </p>
            </div>
          </div>
          <div className="inner-para">
            <p className="list-para">
              ステータスが上がるにつれて
              <br />
              特別な特典を受ける事が可能
            </p>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};
