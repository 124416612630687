import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { fetchSeasonData } from '../services';
import PrivateRoute from '../withPrivateRoute';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, TitleComponent, ValidUntil, TeamCardComponent } from '../components';
import { ChargeAnnualFeeComponent } from '../components/molecules/ChargeAnnualFeeComponent';
import { UserContext } from '../utils/UserContext';

const ContentWrapper = styled.div`
  padding: 35px;
  padding-top: 5px;
  .card-wrapper {
    margin-top: 30px;
  }
  .valid-until-wrapper {
    padding: 20px 25px;
    @media (max-width: 359px) {
      padding: 20px 15px;
    }
  }
  .back-button {
    margin: auto;
    margin-top: 30px;
    max-width: 140px;
  }
`;

const MembershipCardInfoPage = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { push } = useHistory();
  const [chargeAnnualFee, setChargeAnnualFee] = useState(false);
  const { status, teamDetails, expiryDate, userProfileData } = useContext(UserContext);

  const { data: extendDate, isLoading } = useQuery(
    ['fetchSeasonData', teamDetails?.team?.id],
    fetchSeasonData,
    {
      enabled: teamDetails?.team?.id ? true : false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (response) => {
        return response?.data?.extendDate;
      },
    }
  );

  useEffect(() => {
    const _isChargeAnnualFeeTrue =
      search?.split('?')[1]?.split('=')[0] === 'charge-annual-fee' &&
      search?.split('?')[1]?.split('=')[1] === 'true';
    if (_isChargeAnnualFeeTrue) {
      setChargeAnnualFee(true);
    } else {
      setChargeAnnualFee(false);
    }
  }, [search]);

  useEffect(() => {
    if (chargeAnnualFee) {
      document.title = `メンバーシップ有効期限確認・チャージ画面 - Fanique`;
    } else {
      document.title = `年会費支払い画面 - Fanique`;
    }
  }, [chargeAnnualFee]);
  return (
    <Wrapper authenticated>
      <ContentWrapper>
        <TitleComponent
          title
          heading="Exp. Date"
          subHeading="Exp.Date"
          color={teamDetails?.team?.teamColor}
        />
        <div className="card-wrapper">
          <TeamCardComponent />
        </div>
        {chargeAnnualFee ? (
          <>
            <ChargeAnnualFeeComponent
              fee={status?.annualFee.toLocaleString('en-US')}
              color={teamDetails?.team?.teamColor}
              statusId={
                (userProfileData?.membershipStatus?.items || []).find(
                  (item: any) => item.teamId === status?.team?.id
                )?.id
              }
              date={expiryDate}
              extendDate={extendDate}
              valueIsDate
              extendDateLoading={isLoading}
            />
            <div>
              <Button
                color={teamDetails?.team?.teamColor}
                size="small"
                className="back-button"
                onClick={() => push('/membership-card-info')}
              >
                {t('Back')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="valid-until-wrapper">
              <ValidUntil
                color={teamDetails?.team?.teamColor}
                value={new Date(expiryDate)}
                label={t('Valid until')}
                valueIsDate
              />
            </div>
            <div className="button-wrapper">
              {expiryDate === '2025-06-30' ? (
                <Button color={teamDetails?.team?.teamColor} size="large" disabled>
                  {t('Charge annual fee')}
                </Button>
              ) : (
                <Button
                  color={teamDetails?.team?.teamColor}
                  size="large"
                  onClick={() => push('/membership-card-info?charge-annual-fee=true')}
                >
                  {t('Charge annual fee')}
                </Button>
              )}
              <Button
                color={teamDetails?.team?.teamColor}
                size="small"
                className="back-button"
                onClick={() => push('/settings')}
              >
                {t('Back')}
              </Button>
            </div>
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(MembershipCardInfoPage);
